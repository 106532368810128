import React from "react"

import {MainLayout} from "../components/layout";
import {withShopifyBuyButton} from './shopify/shopify_hoc';

// CSS Modules
import bookStyle from "../styles/book.module.styl"
import shared from "../styles/shared.module.styl"

import { BOOK_IMG_MAP, BOOK_BANNER_MAP } from "../pages/libros";

import { PencilRulerIcon, SimpleShopLink, SimpleAmazonShopLink, StoreIcon } from "../components/fa";
import { Link } from "gatsby";
import { Helmety } from "./Head";

export class Blurb extends React.Component {
  render() {
    const {text, source} = this.props;
    return text ? (
      <div className={bookStyle.detailData__blurb} lang="es">
        <blockquote cite={source}>
          <h3 className={bookStyle.text}>{text}</h3>
        </blockquote>
      </div>
    ) : null;
  }
}

class BookDetailPageComponent extends React.Component {
  constructor(props) {
    super(props);
    const {pageContext} = this.props;
    const {book} = pageContext;

    this.state ={
      shopifyId: book.shopifyId
    }
  }

  static defaultProps = {
    pageContext: {
      book: {}
    }
  };

  // componentDidMount() {
  //   const {shopifyId} = this.state;
  //
  //   if (shopifyId) {
  //     if (window.ShopifyBuy) {
  //       if (window.ShopifyBuy.UI) {
  //         this.ShopifyBuyInit();
  //       } else {
  //         this.loadScript();
  //       }
  //     } else {
  //       this.loadScript();
  //     }
  //   }
  // }
  //
  // loadScript() {
  //   const scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
  //   const script = document.createElement('script');
  //   script.async = true;
  //   script.src = scriptURL;
  //   (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
  //   script.onload = this.ShopifyBuyInit;
  // }
  //
  // ShopifyBuyInit = () => {
  //   const {shopifyId} = this.state;
  //
  //   let client = window.ShopifyBuy.buildClient({
  //     domain: 'dharma-books-publishing.myshopify.com',
  //     storefrontAccessToken: 'd059ed8cd9f469938ec83ea5fe71c9a5',
  //   });
  //   window.ShopifyBuy.UI.onReady(client).then(function (ui) {
  //     ui.createComponent('product', {
  //       id: shopifyId,
  //       node: document.getElementById('product-component-1611639279282'),
  //       moneyFormat: '%24%20%7B%7Bamount%7D%7D',
  //       options: {
  //         "product": {
  //           "styles": {
  //             "product": {
  //               "@media (min-width: 601px)": {
  //                 "max-width": "calc(25% - 20px)",
  //                 "margin-left": "20px",
  //                 "margin-bottom": "50px"
  //               }
  //             },
  //             "button": {
  //               "font-family": "Quantico, sans-serif",
  //               "font-weight": "bold",
  //               "font-size": "14px",
  //               "padding-top": "15px",
  //               "padding-bottom": "15px",
  //               ":hover": {
  //                 "background-color": "#dab120"
  //               },
  //               "background-color": "#f2c524",
  //               ":focus": {
  //                 "background-color": "#dab120"
  //               },
  //               "border-radius": "0px"
  //             },
  //             "quantityInput": {
  //               "font-size": "14px",
  //               "padding-top": "15px",
  //               "padding-bottom": "15px"
  //             }
  //           },
  //           "contents": {
  //             "img": false,
  //             "title": false,
  //             "price": false
  //           },
  //           "text": {
  //             "button": "Agregar al carrito",
  //             "outOfStock": "No disponible",
  //             "unavailable": "No disponible"
  //           },
  //           "googleFonts": [
  //             "Quantico"
  //           ]
  //         },
  //         "productSet": {
  //           "styles": {
  //             "products": {
  //               "@media (min-width: 601px)": {
  //                 "margin-left": "-20px"
  //               }
  //             }
  //           }
  //         },
  //         "modalProduct": {
  //           "contents": {
  //             "img": false,
  //             "imgWithCarousel": true,
  //             "button": false,
  //             "buttonWithQuantity": true
  //           },
  //           "styles": {
  //             "product": {
  //               "@media (min-width: 601px)": {
  //                 "max-width": "100%",
  //                 "margin-left": "0px",
  //                 "margin-bottom": "0px"
  //               }
  //             },
  //             "button": {
  //               "font-family": "Raleway, sans-serif",
  //               "font-weight": "bold",
  //               "font-size": "14px",
  //               "padding-top": "15px",
  //               "padding-bottom": "15px",
  //               ":hover": {
  //                 "background-color": "#dab120"
  //               },
  //               "background-color": "#f2c524",
  //               ":focus": {
  //                 "background-color": "#dab120"
  //               },
  //               "border-radius": "0px"
  //             },
  //             "quantityInput": {
  //               "font-size": "14px",
  //               "padding-top": "15px",
  //               "padding-bottom": "15px"
  //             }
  //           },
  //           "googleFonts": [
  //             "Raleway"
  //           ],
  //           "text": {
  //             "button": "Add to cart"
  //           }
  //         },
  //         "cart": {
  //           "styles": {
  //             "button": {
  //               "font-family": "Raleway, sans-serif",
  //               "font-weight": "bold",
  //               "font-size": "14px",
  //               "padding-top": "15px",
  //               "padding-bottom": "15px",
  //               ":hover": {
  //                 "background-color": "#dab120"
  //               },
  //               "background-color": "#f2c524",
  //               ":focus": {
  //                 "background-color": "#dab120"
  //               },
  //               "border-radius": "0px"
  //             }
  //           },
  //           "text": {
  //             "total": "Subtotal",
  //             "button": "Checkout"
  //           },
  //           "googleFonts": [
  //             "Raleway"
  //           ]
  //         },
  //         "toggle": {
  //           "styles": {
  //             "toggle": {
  //               "font-family": "Raleway, sans-serif",
  //               "font-weight": "bold",
  //               "background-color": "#f2c524",
  //               ":hover": {
  //                 "background-color": "#dab120"
  //               },
  //               ":focus": {
  //                 "background-color": "#dab120"
  //               }
  //             },
  //             "count": {
  //               "font-size": "14px"
  //             }
  //           },
  //           "googleFonts": [
  //             "Raleway"
  //           ]
  //         }
  //       },
  //     });
  //   });
  // };

  render() {
    const {pageContext} = this.props;
    const {book} = pageContext;
    const {blurb, purchaseLinks, materials, typography, launchDate, pageCount, authorUrl, author2Url} = book;
    const bookCover = BOOK_IMG_MAP[book.url];
    const banner = BOOK_BANNER_MAP[book.url];

    return (
      <MainLayout siteTitle={book.title}>
        <Helmety
          url={`https://www.dharmabooks.com.mx/libros/${book.url}`}
          title={`${book.title}`}
          content={`${blurb && blurb.text ? blurb.text : book.description}`}
          imgURL={banner}
        />
        <div className={bookStyle.detail}>
          <div className={bookStyle.detail__banner}
               style={{ backgroundImage: `url(${banner})` }}>
          </div>
          <img alt={book.title} className={bookStyle.detail__book} src={bookCover} />
        </div>
        <div className={shared.container}>
          <div className={bookStyle.detailData__main}>
            <h1>{book.title}</h1>
            {authorUrl ?
              <Link to={`/autor/${book.authorUrl}`}><h2>{book.author}</h2></Link> :
              <h2>{book.author}</h2>}
            {author2Url ?
              <Link to={`/autor/${book.author2Url}`}><h2>{book.author2}</h2></Link> :
              <h2>{book.author2}</h2>}
            <div className={bookStyle.detailBuy}>
              <div key={`shopify-btn-catalogo-${book.shopifyId}`} id={`shopify-btn-catalogo-${book.shopifyId}`} />
              <SimpleShopLink icon={<StoreIcon to={"/puntos_de_venta"} />}
                              storeName={"Puntos de venta"}
                              to={"/puntos_de_venta"} />
            </div>
          </div>
          {blurb ?
            <Blurb text={blurb.text} source={blurb.source} /> : ""}

          {book.description ?
            <div className={bookStyle.detailData__description} lang="es">
              <p>{book.description}</p>
            </div> : ""}
          <div className={bookStyle.detailTechnical}>
            <PencilRulerIcon isRound={true} center={true} />
            <br/>
            <p><strong>Fecha de lanzamiento: </strong>{launchDate}</p>
            <p><strong>Número de páginas: </strong>{pageCount}</p>
            <p><strong>Materiales: </strong>{materials}</p>
            {typography && <p><strong>Tipografías: </strong>{typography}</p>}
          </div>
        </div>
      </MainLayout>
    );
  }
}

export default withShopifyBuyButton(BookDetailPageComponent);
